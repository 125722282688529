<div
  class="app-container"
  [ngClass]="{
    'menu-right-on' : menuService.menuRightVisible,
    'courses-page' : isCourses,
    'shadow-none'  : router.url.indexOf('/signup') > -1,
    'bgc-none': (router.url.indexOf('/tutorials') > -1) || (router.url.indexOf('/talent') > -1),
    'full-size': hideShell
  }"
>
  <app-mobile-menu *ngIf="!hideShell"></app-mobile-menu>
  <app-header *ngIf="!hideShell"></app-header>

  <div
    *ngIf="(clientLoaded$ | async) || !loggedIn; else AppLoadingScreen"
    [@fadeInOut]
    class="main-body"
    device
    [ngClass]="
    {
      'pb-0 position-absolute h-100': router.url.indexOf('/signup') > -1,
      'aj-home': router.url.endsWith('/add-job'),
      'full-size': hideShell
    }">
      <router-outlet></router-outlet>
  </div>
  <ng-template #AppLoadingScreen>
    <app-loading-screen></app-loading-screen>
  </ng-template>

  <app-footer *ngIf="!hideShell"></app-footer>
  
</div>
