import { Component, Input } from '@angular/core';
import { AvailabilityDay, getAvailabilityTypeText } from '@app/models/availability-day.model';
import { CustomFormat } from '@app/localization/providers/localized-date-format.provider';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

@Component({
  selector: 'app-availability-day',
  templateUrl: './availability-day.component.html',
  styleUrls: ['./availability-day.component.scss']
})
export class AvailabilityDayComponent {
  protected readonly getAvailabilityTypeText = getAvailabilityTypeText;
  @Input() idPrefix: string;
  @Input() candidateId: string;
  @Input() availabilityDay: AvailabilityDay;
  @Input() avDayIndex: number;

  @Input() faded: boolean = false;
  @Input() isSelected: boolean = false;
  @Input() currentDay: Dayjs = dayjs();

  topDateFormat = CustomFormat.AvailabilityCircleUpper;
  bottomDateFormat = CustomFormat.AvailabilityCircleLower;

  isPastDay() {
    return this.availabilityDay?.Date.isBefore(this.currentDay, 'day');
  }
}
