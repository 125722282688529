import { Pipe, PipeTransform } from '@angular/core';
import { LocalizeDatePipe } from '@app/localization/pipes/localize-date.pipe';
import { DateFormat } from '@app/localization/providers/localized-date-format.provider';
import dayjs from 'dayjs';

@Pipe({
  name: 'messageTime'
})
export class MessageTimePipe implements PipeTransform {

  constructor(private localizedDatePipe: LocalizeDatePipe) {}

  transform(value: any, args?: any): any {
    let messageDateTime = dayjs(value);
    
    if (!messageDateTime.isValid()) {
      return null 
    }

    let timeValue = value * 1000;

    let result = this.localizedDatePipe.transform(dayjs(timeValue), DateFormat.TimeSimple);
      
    let today = dayjs.utc(new Date());

    if (!dayjs.utc(timeValue).isSame(today, 'year')) {
      result += ", " + this.localizedDatePipe.transform(dayjs(timeValue), DateFormat.DateMonthShortYear);
    } else {
      if (!dayjs.utc(timeValue).isSame(today, 'day')) {
      result += ", " + this.transform(DateFormat.DayDateMonthShort);
      }
    }

    return  result; 
  }

}
