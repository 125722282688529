import { Pipe, PipeTransform } from '@angular/core';
import { isLabel, Label, LocalizedTextProvider, Symbol } from '../providers/localized-text.provider';

@Pipe({
  name: 'localizeText'
})
export class LocalizeTextPipe implements PipeTransform {
  casingFn: { [key: string]: (value: string) => string } = {};

  constructor(private provider: LocalizedTextProvider) {
    // All lowercase
    this.casingFn['LC'] = function (value: string): string { return value.toLowerCase(); }
    // All uppercase
    this.casingFn['UC'] = function (value: string): string { return value.toUpperCase(); }
    // Uppercase first letter
    this.casingFn['UCF'] = function (value: string): string {
        return `${value.slice(0, 1).toUpperCase()}${value.slice(1).toLowerCase()}`;
    }
  }

  transform(label: Label, casing: string = ''): string {
    let localizedText =  this.provider.getLocalizedText(label);

    if (isLabel(label) && !!casing) {
      return this.casingFn[casing](localizedText);
    }

    return localizedText;
  }

}
