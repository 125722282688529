import { Injectable, OnDestroy } from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, catchError } from 'rxjs/operators';

import { ClientService, AuthService } from '../services';
import { ClientDataStoreService } from '@app/services/client-data-store.service';

@Injectable()
export class LoginResolver  implements OnDestroy {
  userInfoCache: any;
  logOutEventSubscription: any;

  constructor(
    private auth: AuthService,
    private clientStore: ClientDataStoreService,
    private router: Router,
  ) {
    this.logOutEventSubscription = this.auth.LogOutEvent.subscribe(() => {
      this.userInfoCache = null;
    });
  }

  ngOnDestroy() {
    this.logOutEventSubscription.unsubscribe();
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<any> {
    if (!this.userInfoCache) {
      
      return this.clientStore.clientSummary$.pipe(
        map(result => {
          this.userInfoCache = result;
          return result;
        }),
        catchError(error => {
          if(error.error.Message == "Authorization has been denied for this request."){
            this.router.navigate(['/sorry']);
          }
          return of(null);
        })
      );
    }

    return of(this.userInfoCache);
  }
}
