import { Pipe, PipeTransform } from '@angular/core';
import { AvailabilityDay, AvailabilityType, getAvailabilityTypeText } from '@models/availability-day.model';
import dayjs from 'dayjs';


@Pipe({
  name: 'availabilityTt'
})
export class AvailabilityTooltipPipe implements PipeTransform {
  protected readonly getAvailabilityTypeText = getAvailabilityTypeText;

  transform(value: AvailabilityDay, currentDate: Date | string | dayjs.Dayjs): any {
    const now = dayjs.isDayjs(currentDate) ? currentDate : dayjs(currentDate);
    const availability = dayjs.isDayjs(value.Date) ? value.Date : dayjs(value.Date);
    let tooltip = '';
    if (now.isSameOrBefore(availability, 'day')) {
      tooltip = this.getAvailabilityTypeText(value.Type);
    } else {
      tooltip = 'Past day';
    }

    return tooltip;
  }

}
