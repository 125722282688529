<div class="header__outer" [ngClass]="{
    'desktop': !isMobile,
    'mobile': isMobile,
    'loged-out': false | authorization,
    'menu-right-on': menuService.menuRightVisible,
    'reference-page': router.url.indexOf('/reference') > -1,
    'talent-page': router.url.indexOf('/talent') > -1,
    'no-header-footer': noHeaderFooter
  }">

  <div #headerSpacer class="headerSpacer" [ngClass]="{
      'loging-in': false | authorization,
      'secondary-menu--visible': showOpogoHub || showMyAccount
    }">
  </div>

  <div #headerContainer class="fixedHeader">

    <!-- cookie -->
    <div *ngIf="showCookieLaw" class="cookieLaw">
      <div #cookieLawContainer class="container">
        <div class="row">
          <div class="col-12">
            <div class="pr-0">
              <span class="li_world"></span>
            </div>

            <div class="text-center pl-0">
              <a (click)="hideCookieLaw()">&times;</a>
            </div>

            <p>
              We use cookies on this site to personalise your experience. For more info or to change settings, click
              <a>here</a>.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="headerMenu" [ngClass]="{'loging-in': false | authorization }" device>

      <!-- main menu -->
      <div #mainMenuContainer class="header-menu-container">
        <div class="row" *ngIf="true | authorization">
          <div class="col-12 px-0 px-lg-3">
            <nav class="navbar navbar-expand-lg" [ngClass]="{ 'in': isIn }">
              <div class="logo-container d-lg-none mr-auto" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{match:['/add-job']}" routerLink="/add-job" *ngIf="hasJobBookingPermission">
                <img class="opogo-logo" src="assets/img/opogo_logo_mobile.png">
              </div>

              <div class="nav-item d-lg-none align-items-center mr-3" (click)="logEventForChat()">
                <a class="nav-link chat content-icon" (click)="showChatPopup()">
                  <i class="m-icon va-m md">textsms</i>
                  <span *ngIf="chatService.unreadMessages > 0">{{getChatBadgeCount()}}</span>
                </a>
              </div>

              <div class="nav-item  d-lg-none align-items-center mr-4">
                <div class="nav-link notifications">
                  <app-notifications></app-notifications>
                </div>
              </div>

              <div class="profile-img-container d-none mb-2">
                <a class="square user-profile-img op-avatar  menu-toggler-secondary float-right"
                  (click)="showHideRightMenu()">
                  <img *ngIf="userInfo?.ProfileImageUrl" class="content" [src]="getLinkPicture()">
                </a>
                <span *ngIf="getBadgeCount()">{{getBadgeCount()}}</span>
              </div>

              <button class="menu-toggler d-lg-none mt-0" type="button" (click)="toggleState();showHideRightMenu()">
                <span></span>
                <span></span>
                <span></span>
              </button>

              <div class="d-none flex-grow-1 justify-content-between d-lg-flex">
                <a class="menu-close__mobile d-lg-none" (click)="toggleState()">&times;</a>

                <div class="logo-wrap">
                  <div class="logo-container" (click)="goToHome()" (click)="logEventForLogo()">
                    <img class="opogo-logo" [attr.data-au]="'opogo-logo'" src="assets/img/opogo_logo.png">
                  </div>

                  <div class="logo-container with-separator" *ngIf="!isTrustLevel && userInfo?.PartnerLogoUrl"
                    (click)="goToHome()" (click)="logEventForLogo()">
                    <div class="v-separator"></div>

                    <img class="heathcote-logo" [src]="userInfo?.PartnerLogoUrl"
                      (error)="errorPartnerLogoHandler($event)" [attr.data-au]="'school-logo'">
                  </div>

                  <app-client-select-dropdown *ngIf="isTrustLevel"></app-client-select-dropdown>
                </div>

                <div class="nav-items">
                  <ul class="navbar-nav mr-auto">
                    <li class="nav-item d-md-flex align-items-md-center" [routerLinkActive]="['active']"
                      [routerLinkActiveOptions]="{match:['/add-job']}" *ngIf="hasJobBookingPermission">
                      <a class="nav-link" [routerLink]="['/add-job']" (click)="toggleState()"
                         (click)="logEventForAddJob()">Book {{ localizedLabel.SupplyUC | localizeText }}</a>
                    </li>
                    <li class="nav-item d-md-flex align-items-md-center" [routerLinkActive]="['active']"
                      [routerLinkActiveOptions]="{match:['/manage-jobs']}" *ngIf="hasJobBookingPermission">
                      <a class="nav-link" [routerLink]="['/manage-jobs']" (click)="toggleState()"
                         (click)="logEventForManageJobs()">Manage jobs & Timesheets</a>
                    </li>
                    <li class="nav-item d-md-flex align-items-md-center" [routerLinkActive]="['active']"
                      [routerLinkActiveOptions]="{match:['/talent']}" *ngIf="hasTalentPoolUsePermission">
                      <a class="nav-link" [routerLink]="['/talent']" (click)="toggleState()"
                         (click)="logEventForTalent()">Talent</a>
                    </li>
                    <li class="nav-item d-md-flex align-items-md-center" *ngIf="hasJobSigningOffPermission">
                    <a class="nav-link" target="_blank" href="{{ joinCommunityLink }}" (click)="toggleState()"
                       (click)="logEventForCommunity()">Learn</a>
                  </li>
                    <!-- <li class="nav-item d-md-flex align-items-md-center" [ngClass]="{'active': showOpogoHub}"
                      *ngIf="hasContentViewingPermission">
                      <app-hub-nav-list [hasContentCreationPermission]="hasContentCreationPermission">
                      </app-hub-nav-list>
                    </li> -->
                  </ul>

                  <div class="v-separator" *ngIf="
                      hasTalentPoolUsePermission ||
                      hasContentViewingPermission ||
                      hasJobSigningOffPermission
                    "></div>

                  <ul class="navbar-nav account-config">
                    <li class="nav-item d-md-flex align-items-md-center" [routerLinkActive]="['active']"
                      [routerLinkActiveOptions]="{match:['/tutorials']}" *ngIf="hasClientAdministratorPermission"
                        (click)="logEventForHelp()">
                      <a class="nav-link content-icon" [routerLink]="['/tutorials']" (click)="toggleState()">
                        <i class="m-icon va-m ro">
                          help
                        </i>
                      </a>
                    </li>
                    <li class="nav-item d-md-flex align-items-center">
                      <div class="nav-link notifications">
                        <app-notifications></app-notifications>
                      </div>
                    </li>
                    <li>
                      <app-account-nav-list [faceImageUrl]="getLinkPicture()" (click)="logEventForProfile()"
                        [hasClientAdministratorPermission]="hasClientAdministratorPermission"
                        (changeImage)="openModal(ChangeImage, userInfo)" (logout)="logout()"></app-account-nav-list>
                    </li>
                  </ul>
                </div>
              </div>
            </nav>
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- step x of y -->
  <div *ngIf="headerData.title" class="row justify-content-center header-user-profile"
    [ngClass]="{ 'not-mobile': !isMobile }">
    <div class="col-sm-auto text-center align-self-center mb-3">
      <h4 *ngIf="headerData.title">{{headerData.title}}</h4>
      <p *ngIf="headerData.subtitle" innerHTML="{{headerData.subtitle}}"></p>

      <div *ngIf="headerData.showStepsCount" class="badge badge-pill badge-secondary">STEP {{headerData.currentStep}}
        OF {{headerData.totalSteps}}</div>
    </div>
  </div>

  <!-- HUB secondary-menu - mobile -->
  <div class="secondary-menu--mobile d-md-none" *ngIf="showOpogoHub && !isContentDetails && !isCourseDetails">
    <div class="container">
      <div class="row justify-content-center">
        <div class="col-12 col-sm-8 col-md-6">
          <div class="row">
            <div class="col-3 text-center">
              <a class="secondary-menu__inner" [routerLink]="['hub']" [ngClass]="{'active': isLatestPosts}">
                <span class="opicon o-star-gray-outline"></span>
                <span class="secondary__text">Latest</span>
              </a>
            </div>

            <div class="col-3 text-center">
              <a class="secondary-menu__inner" [routerLink]="['hub/experts']" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{match:['/hub/experts']}">
                <span class="opicon o-daily"></span>
                <span class="secondary__text">Experts</span>
              </a>
            </div>

            <div class="col-3 text-center">
              <a class="secondary-menu__inner" [routerLink]="['hub/courses']" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{match:['/hub/courses']}">
                <span class="opicon o-courses"></span>
                <span class="secondary__text">Courses</span>
              </a>
            </div>

            <div class="col-3 text-center">
              <a class="secondary-menu__inner" [routerLink]="['hub/events']" [routerLinkActive]="['active']"
                [routerLinkActiveOptions]="{match:['/hub/events']}">
                <span class="opicon o-events"></span>
                <span class="secondary__text">Events</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- Invoice detail modal -->
<ng-template #ChangeImage data-backdrop="static" data-keyboard="false">
  <div class="modal-header box-header">
    <span *ngIf="!cropping.isLoaded && !cancelCrop">Update profile picture</span>
    <span *ngIf="cropping.isLoaded && !cancelCrop && !cropping.isCropped">Adjust profile picture</span>
    <span *ngIf="cancelCrop">Changes not saved</span>
    <span *ngIf="cropping.isCropped">successful</span>
    <button *ngIf="!cropping.isCropped && cropping.isLoaded" type="button" class="close pull-right"
      [ngClass]="{'d-none': cancelCrop}" aria-label="Close" (click)="cancelCropping()"><span
        aria-hidden="true">&times;</span>
    </button>
    <button *ngIf="!cropping.isLoaded || isCropped" type="button" class="close pull-right"
      [ngClass]="{'d-none': cancelCrop}" aria-label="Close" (click)="closeModal();cropping.clean()"><span
        aria-hidden="true">&times;</span></button>
  </div>

  <div class="modal-body box-body border-0 px-0 pt-3" [ngClass]="{ 'no-image-loaded' : !cropping.isLoaded,
                   'cancel-cropping' : cancelCrop,
                   'image-cropped'   : cropping.isCropped }">
    <!-- current image -->
    <div class="square user-profile-img op-avatar d-block" *ngIf="!cropping.isLoaded && !cancelCrop">
      <img *ngIf="modalRef.content.userImage" class="content" [src]="getLinkPicture()">
    </div>

    <!-- uppload new image -->
    <div class="d-flex justify-content-center" [withClass]="classes.actions">
      <button class="btn btn-primary upload-image-btn" raised ly-button bg="primary" (click)="_fileInput.click()"
        *ngIf="!cropping.isLoaded && !cancelCrop">
        <span>Upload a new photo</span>
      </button>

      <!-- Hidden input -->
      <input #_fileInput type="file" (change)="cropping.selectInputEvent($event)" accept="image/*" hidden>
    </div>

    <!-- crop UI -->
    <div class="image-cropper-wrapper">
      <ly-img-cropper #cropping [withClass]="classes.cropping" [config]="myConfig" [(scale)]="scale"
        (loaded)="onloaded($event)" (cropped)="onCrop($event)"></ly-img-cropper>

      <p *ngIf="cropping.isLoaded && !cancelCrop && !cropping.isCropped" class="mb-0">Drag to move image</p>
    </div>

    <!-- cropping controlls -->
    <div *ngIf="cropping.isLoaded && !cancelCrop && !cropping.isCropped" class="row controlls-row mx-0 pb-3 pb-sm-0">
      <div class="col-12 col-sm-6">

        <!-- input range -->
        <div *ngIf="cropping.isLoaded" [className]="classes.range">
          <p class="d-inline-block p-0 my-0 mr-1">Zoom:</p>
          <small class="zoom-icon"></small>
          <input type="range" [className]="classes.rangeInput" [attr.min]="cropping.minScale" max="1"
            [(ngModel)]="scale" step="any">
          <span class="zoom-icon"></span>
        </div>
      </div>

      <div class="col-12 col-sm-6 d-flex align-items-center justify-content-center justify-content-sm-end">
        <button class="controlls-row__cancel-crop mr-4 p-0" (click)="cancelCropping()">Cancel</button>
        <button class="save-crop" color="accent" (click)="cropping.crop()" ly-button>Save</button>
      </div>
    </div>

    <!-- cropped image -->
    <div class="successful-cropping" *ngIf="cropping.isCropped">
      <div class="square user-profile-img op-avatar d-block">
        <img class="content" [src]="croppedImg" alt="Profile image">
      </div>

      <p class="mb-4">Awesome, your new profile picture <br> has been added.</p>

      <div class="successful-cropping__buttons d-flex align-items-center justify-content-end p-3 border-top">
        <button class="btn btn-primary m-0" (click)="closeModal();cropping.clean()">Close</button>
      </div>
    </div>

    <!-- cancel cropping -->
    <div class="cancel-cropping" *ngIf="cancelCrop">
      <p class="p-5 m-5">The changes you've made to your profile picture won't be saved if you leave this page.</p>

      <div class="cancel-cropping__buttons d-flex align-items-center justify-content-end p-3">
        <button class="mx-3 p-0" (click)="cancelCropping()">Continue Updating</button>
        <button class="btn btn-primary m-0" (click)="closeModal();cropping.clean()">Leave this page</button>
      </div>
    </div>
  </div>
</ng-template>
