import { Pipe, PipeTransform } from '@angular/core';
import { DateFormat, LocalizedDateFormatProvider } from '../providers/localized-date-format.provider';
import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';

@Pipe({
  name: 'localizeDate'
})
export class LocalizeDatePipe implements PipeTransform {

  constructor(private provider: LocalizedDateFormatProvider) { }

  transform(value: Date | string | Dayjs, format: DateFormat): string | null {
    const date = dayjs(value);

    if (!date.isValid()) {
      return null;
    }

    let localizedFormat = this.provider.getLocalizedDateFormat(format);
    
    return date.format(localizedFormat);
  }

}
