import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs';
import { HttpService } from './http.service';
import { StaffPagingSortingFiltering, Staff } from '../interfaces/staff-management';
import { AuthService } from '@services/auth.service';

@Injectable()
export class StaffManagementService {
  successfullyAdded: string;
  successfullyDeleted: string;

  constructor(
    private http: HttpService,
    private auth: AuthService
  ) {
  }

  getClientStaffFiltered(staffPagingSortingFiltering: StaffPagingSortingFiltering, clientId: string): Observable<any> {
    let url: string = `/staffmanagement/getClientStaffFiltered?clientId=${clientId}`;
    return this.http.post(url, staffPagingSortingFiltering);
  }

  getCSVSpreadsheetTemplate(): Observable<any> {
    let url: string = `/staffmanagement/getCSVSpreadsheetTemplate`;
    return this.http.get(url, {responseType: 'arraybuffer'});
  }

  getClientStaff(id: any): Observable<any> {
    let url: string = `/staffmanagement/getClientStaffById?id=${id}`;
    return this.http.get(url);
  }

  importStaff(file, clientId: string): Observable<any> {
    let url: string = `/staffmanagement/bulkImportStaff?clientId=${clientId}`;

    const headers = new HttpHeaders({
      'Authorization': `bearer ${this.auth.token}`
    });

    const formdata: FormData = new FormData();
    formdata.append('file', file);

    return this.http.post(url, formdata, {headers: headers});
  }

  saveStaff(staff: Staff, clientId: string): Observable<any> {
    let url: string = `/staffmanagement/saveStaff?clientId=${clientId}`;
    return this.http.post(url, staff);
  }

  toggleStaffActive(id: any): Observable<any> {
    let url: string = `/staffmanagement/toggleStaffActive?staffId=${id}`;
    return this.http.get(url);
  }

  resendStaffInvite(id: any): Observable<any> {
    let url: string = `/staffmanagement/resendStaffInvite?staffId=${id}`;
    return this.http.get(url);
  }

  deleteAccount(id: any): Observable<any> {
    let url: string = `/staffmanagement/deleteAccount?staffId=${id}`;
    return this.http.get(url);
  }

  getStaffFeatures(): Observable<any> {
    let url: string = `/staffmanagement/getStaffFeatures`;
    return this.http.get(url);
  }
}
