import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { InfiniteScrollModule } from "ngx-infinite-scroll";
import { CurrencyMaskModule } from "ng2-currency-mask";

import {
  AutoplayPipe,
  BookingDaysPipe,
  LimitToPipe,
  SafePipe,
  SecondsToTimePipe,
  DisplayNumberPipe,
  SetMinimumDurationConverterPipe,
} from "./pipes";
import { AutoresizeDirective, DeviceDirective } from "@directives";
import { HttpService } from "./services";
import { AvailabilityPipe } from "@pipes/availability.pipe";
import { EqualValidator } from "@directives/validate-equal";
import { PasswordVisibilityDirective } from "@directives/password-visibility.directive";
import { TimeRangeConfigPipe } from "./pipes/time-range.pipe";
import { ClickOutsideDirective } from "./directives/click-outside.directive";
import { AvailabilityTooltipPipe } from "./pipes/availability-tooltip.pipe";
import {MatTooltipModule} from '@angular/material/tooltip';
import { TimeDurationPipe } from "./pipes/time-duration.pipe";

@NgModule({
  imports: [
    InfiniteScrollModule, 
    CurrencyMaskModule, 
    MatTooltipModule,
    TimeDurationPipe
  ],
  declarations: [
    AutoplayPipe,
    SecondsToTimePipe,
    SafePipe,
    BookingDaysPipe,
    LimitToPipe,
    AutoresizeDirective,
    AvailabilityPipe,
    DisplayNumberPipe,
    SetMinimumDurationConverterPipe,
    TimeRangeConfigPipe,
    DeviceDirective,
    EqualValidator,
    PasswordVisibilityDirective,
    ClickOutsideDirective,
    AvailabilityTooltipPipe
  ],
  exports: [
    CommonModule,
    InfiniteScrollModule,
    CurrencyMaskModule,
    AutoplayPipe,
    SecondsToTimePipe,
    SafePipe,
    BookingDaysPipe,
    AutoresizeDirective,
    LimitToPipe,
    AvailabilityPipe,
    DisplayNumberPipe,
    SetMinimumDurationConverterPipe,
    TimeRangeConfigPipe,
    DeviceDirective,
    EqualValidator,
    PasswordVisibilityDirective,
    ClickOutsideDirective,
    AvailabilityTooltipPipe,
    TimeDurationPipe,
    MatTooltipModule,
  ],
  providers: [HttpService]
})
export class SharedModule {}
