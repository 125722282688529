<div class="overlay" *ngIf="clientDropdownExpanded" (click)="toggleDropdown()"></div>

@if (mainClient && mainClient.Clients.length > 0) {
    <div class="client-select-dropdown ml-4" [ngClass]="{ 'expanded': clientDropdownExpanded }">
        <div class="dropdown-head client-item" (click)="toggleDropdown()">
            <div class="logo-container">
                @if (selectedClient.LogoIconImageUrl) {
                    <img class="client-logo" [src]="selectedClient.LogoIconImageUrl">
                }
                @else {
                    <div class="client-logo-placeholder"></div>
                }
            </div>
            <div class="name-container pr-4">
                <strong class="client-name">{{ selectedClient.Name }}</strong>
            </div>
            <i class="m-icon va-m ro md expand px-2">expand_more</i>
        </div>
        
        @if (clientDropdownExpanded) {
            <ng-container *ngTemplateOutlet="ClientTree; context:{ clientTree: mainClient }"></ng-container>
        }
    </div>
}

<ng-template #ClientTreeItem let-clientItem="clientItem" let-depth="depth">
    <div class="client-item" (click)="onClientClicked(clientItem)"
        [ngClass]="{
            'main': clientItem.Id === mainClient.Id,
            'selected': clientItem.Id === selectedClient.Id,
            'has-children': clientItem.Clients.length > 0,
            'expanded': clientItem.Expanded 
        }"
        [ngStyle]="{'padding-left.px': depth * 15}">
        <div class="logo-container">
            @if (clientItem.LogoIconImageUrl) {
                <img class="client-logo" [src]="clientItem.LogoIconImageUrl">
            }
            @else {
                <div class="client-logo-placeholder"></div>
            }
        </div>
        @if (clientItem.Clients.length > 0) {
            <div class="client-expand-arrow-container">
                <div class="client-expand-arrow">
                    <i class="m-icon va-m ro collapsed">chevron_right</i>
                    <i class="m-icon va-m ro expanded">expand_more</i>
                </div>
            </div>
        }
        <div class="name-container pr-4">
            <strong class="client-name">{{ clientItem.Name }} {{ clientItem.Id !== mainClient.Id && clientItem.Clients.length > 0 ? '(' + clientItem.Clients.length + ')' : '' }}</strong>
        </div>
    </div>
    <div class="client-children" *ngIf="clientItem.Clients.length && clientItem.Expanded">
        @for (childClient of clientItem.Clients; track childClient) {
            <ng-container *ngTemplateOutlet="ClientTreeItem; context:{ clientItem: childClient, depth: depth + 1 }"></ng-container>
        }
    </div>
</ng-template>

<ng-template #ClientTree let-clientTree="clientTree">
    <div class="client-tree">
        <ng-container *ngTemplateOutlet="ClientTreeItem; context:{ clientItem: clientTree, depth: 0 }"></ng-container>
    </div>
</ng-template>