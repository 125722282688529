// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --configuration=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  servicesEmail: 'services@opogo.com',
  apiBaseURL: 'https://api-dev.opogo.com/api',
  opogoPlatformApiUrl: "https://opogo-platform-dev-a6gpbbf2ehdsfce3.uksouth-01.azurewebsites.net",
  appUrl: 'https://app-dev.opogo.com',
  websiteUrl: 'https://uk.opogo.com',
  schoolSignUpUrl: 'https://uk.opogo.com/school-signup',
  addBankStaffUrl: 'https://uk.opogo.com/bankstaff',
  bannerFile: './assets/banner.json',    
  loginExprirationDeviation: 4 * 60000, // 4min
  loginBaseConf: {
    client_id: 'Angular',
    client_secret: 'u4jU8$Hc',
    Grant_Type: 'password'
  },
  locale: 'en-GB',
  countryCode: 'GB',
  googleMaps: {
      apiKey: 'AIzaSyBIQYX77Qu9INnBlNwzVhsaGk2pFF1nb1g'
  },
  firebaseConfig: {
      apiKey: 'AIzaSyDAIxtFw-VM6pzy2FegIeSOR7aiBFS30mQ',
      authDomain: 'opogo-web-app.firebaseapp.com',
      projectId: 'opogo-web-app',
      storageBucket: 'opogo-web-app.appspot.com',
      messagingSenderId: '330488512762',
      appId: '1:330488512762:web:e307bdb9ca4ee83fae0943',
      measurementId: 'G-MB7D4N763R'
  },
  hubspotSignup: "https://uk.opogo.com/signup",
  termsOfServiceUrl: "https://www.opogo.com/terms",
  signalRBaseURL: 'https://api-qa.opogo.com/signalr',
  blobStorageURL: 'https://opogostorage.blob.core.windows.net',
  learnUrl: "https://community.opogo.com",
  appStoreUrl: "https://apps.apple.com/gb/app/opogo/id1363875192",
  playStoreUrl: "https://play.google.com/store/apps/details?id=com.opogo",
  pubNub: {
      publishKey: 'pub-c-3102a0cd-a085-43e7-8f7f-6960929d3543',
      subscribeKey: 'sub-c-39cb1a04-12f4-11e8-bb6e-d6d19ee12a32'
  },
  pushNotifications: {
      vapidKey: 'BFR3AeJqGOhkj4r8_X2XUBocXy58haEDRkZhLHOB5Zj1rShikVmPeHOel6DpwVC_e1tMbsd8o2JoQdLE3DoYQrA'
  },
  invigSchools: {
      academy: 4,
      secondarySchool: 5,
      sendSchool: 6,
      allThroughSchool: 10,
      FECollege: 12
  },
  jobRoles: {
    examInvigilatorRoleId: '9A4CE02F-BF5C-4E1E-86CC-C6EC29DA96F5',
    partnerSubId: ''
  },
  jobRoleGroups: {
    teachers: 1,
    teachingAssistants: 2,
    coverSupervisors: 3,
    examInvigilators: 4
  },
  tagCategories: {
    qualificationsCategoryId: 'b4dd9f9f-1eb3-4289-ac43-0d07e11061c4'
  },
  serviceFeeOverrides: {
    examInvigHalfDay: 15,
    bankStaff: 15
  },
  examStartDate: "2024-11-04",
  paymentURL: 'https://login.xero.com/',
  contentExperts: ['47eefd1e-8aa4-4cdc-8bdc-9c7aec034f9e', 'bcb32826-04c7-4c65-9e04-05f6b616ca54', '466eed48-0f4d-4eac-b97e-0a7e4fb24a31', '43257a3c-94da-4df7-9ea5-438b9c21b2c8', '8bfa2792-1c9d-4312-81fc-9da1b2362a40', '742eca28-7d7a-41c7-b065-ac2816d4861e', 'cd22996e-321e-4515-9afa-8b8b619a0c17', '1360288d-b529-43a8-9041-ecb7765acf2c', '5c3f4fbd-11f6-4877-a84f-f188b754a0ad', '40d34f42-0476-4eda-9ff8-afebc02e4ecb', '95c1b68d-efba-4170-9e13-16cbbab07d97', 'b9497058-cb12-47ea-a994-b72e8a1500b4', '376be306-2cb4-4627-bcbb-2ec7b098dc0a', '82934d3e-faa8-4feb-837b-18416c6d02be'],
  signUpJobRoleList: [{
      name: 'Primary Teacher',
      id: '08A7D92F-F3CA-4430-9276-247AF392CBCF'
    }, {
      name: 'Primary Teaching Assistant',
      id: '409A5801-BF72-45DE-A514-C41646E7CA98'
    }, {
      name: 'Secondary Teacher',
      id: '5B3C8EED-BBB3-48A6-9FEE-988FB0113A69'
    }, {
      name: 'Cover Supervisor',
      id: '8F2F0FF2-4961-4952-BB12-EB942A4B89EF'
    },
    {
      name: 'Secondary Teaching Assistant',
      id: '8EE29F2B-3043-42BA-9642-2FDF549E2D46'
    },
    {
      name: 'Other',
      id: 'null'
    }
  ],
  Caches: {
      UserInfo: 0
  },
  FEATURE_FLAGS: {
      DARTG: true,
      CANDIDATE_SUMMARY: true,
      VIEW_CV: true,
      NOTIFICATION_SETTINGS: true,
      TRAVEL_BUDDY: true,
      VETTING_STATEMENT: true,
      STAFF_MANAGEMENT: true,
      INVOICES: true,
      TALENT_POOL_WIDGET: true,
      TIMESHEET_WIDGET: true,
      TALENT_PROFILE_WIDGET: true,
      MANAGE_JOBS_WIDGET: true
  }
};
