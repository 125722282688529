import { Dayjs } from 'dayjs';
import { AvailabilityDay, TermAvailabilityType } from '../models/availability-day.model';
import { BaseModel } from '../models/base.model';
import dayjs from 'dayjs';
import * as _ from 'underscore';
import { DateFormatConstants } from '../utils/date';
import { MemberTagCategory, Tag } from './tag.model';

export class Candidate extends BaseModel {
  CandidateId: string = null; // TO DO: remove, and use Id.  
  Addedby: AddedBy = null;
  Area: string = null;
  Availability: AvailabilityDay[] = [];
  LongTermPreference?: CandidateLongTermPreference = null;
  SelectedDays: AvailabilityDay[] = [];
  AvailabilityTypeId: any = null;
  AvailableFromDate: any = null;
  FavouriteId: number = null;
  FirstName: string = null;
  LastName: string = null;
  DisplayName: string = null;
  DefaultSummary: string = null;
  Jobs: number = null;
  JobsAtClient: number = null;
  JourneyTime: number = null;
  JourneyTimes = [];
  Photo: string = null;
  ProfileImageURL: string = null; // TO DO: either remove this or Photo
  Profiles: CandidateProfile[] = [];
  MatchedProfile: any = null;
  Tags: Tag[] = null;
  Score: number = null;
  TotalDays: number = null;
  TotalDaysAtClient: number = null;
  UserId: string = null;
  Recommended: boolean = false;
  RecommendedBy?: string = null;
  RecommendedByPhotoURL?: string = null;
  IsBankStaff: boolean = false;
  TopMatch: boolean = false;
  ReadyToGo: boolean = false;
  ReviewScore?: number = null;
  BookAgain?: number = null;
  IsAvailable: boolean = false;
  InTalentPool?: boolean = false;
  IsDeleted: boolean = false;
  CourseCount: number = 0;
  RecentCourses: string[] = [];
  TermlyAvailability: TermAvailabilityType = null;
  DailySupplyAvailability: TermAvailabilityType = null;
  SupplyAvailabilityKnown: boolean = false;
  RoleGroups: number[] = [];

  get FullDayRate(): number {
    return this.Profiles[0].DayPayRate;
  }

  get HalfDayRate(): number {
    return this.Profiles[0].HalfDayPayRate;
  }

  get HourlyRate(): number {
    return this.Profiles[0].HourlyRate;
  }

  get LongTermDayRate() {
    return this.Profiles[0].LongTermDayPayRate;
  }
  
  deserialize(input: any): this {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (!this.isPropertyWritable(key)) {
          return;
        }

        if (typeof this[key] !== 'undefined') {
          if (key === 'Availability') {
            input[key].forEach(day => {
              this[key].push(new AvailabilityDay().deserialize(day));
            });
          } else if (key === 'Profiles') {
            input[key].forEach(profile => {
              this[key].push(new CandidateProfile().deserialize(profile));
            });
          } else if (key === 'AddedBy') {
            this[key] = new AddedBy().deserialize(input[key]);
          } else if (key === 'LongTermPreference' && !!input[key]) {
            this[key] = new CandidateLongTermPreference().deserialize(input[key]);
          } else if (key === 'MemberSince') {
            this[key] = dayjs(input[key], DateFormatConstants.YearMonthDayFormat);
          } else {
            this[key] = input[key];
          }
        }
      });

    return this;
  }

  isSelected(): boolean {
    return !!this.SelectedDays.length;
  }

  getSelectedDay(day: string | Date | dayjs.Dayjs) {
    const date = dayjs.isDayjs(day) ? (<dayjs.Dayjs>day).clone() : dayjs(day);
    const selected = this.SelectedDays.find(data => date.format('MM/DD/YYYY') === data.Date.format('MM/DD/YYYY'));
    if (selected) {
      return selected;
    }
    return null;
  }

  getSelectedDayIndex(day: string | Date | dayjs.Dayjs) {
    const date = dayjs.isDayjs(day) ? (<dayjs.Dayjs>day).clone() : dayjs(day);
    const index = this.SelectedDays.findIndex(data => date.format('MM/DD/YYYY') === data.Date.format('MM/DD/YYYY'));
    if (index > -1) {
      return index;
    }
    return -1;
  }

  removeSelectedDay(day: string | Date | dayjs.Dayjs) {
    const index = this.getSelectedDayIndex(day);
    if (index > -1) {
      this.SelectedDays.splice(index, 1);
      return true;
    }
    return false;
  }

  clone() {
    return _.clone(this);
  }
}

export class MatchResponse {
  TotalResult: number = 0;
  MatchedCandidates: Candidate[] = null;
}

export class CandidateProfile extends BaseModel {
  CandidateId: string = null;
  IsPrimary: number = null;
  Longterm: boolean = null;
  Role: string = null;
  RoleId: string = null;
  RoleGroupId: number = null;
  Summary: string = null;
  SummarySlice: string = null;
  Supply: boolean = null;
  Rate: number = null;
  DayPayRate: number = null;
  HalfDayPayRate: number = null;
  HourlyRate: number = null;
  LongTermDayPayRate: number = null;
  Tags: any[] = [];

  deserialize(input: any): this {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (!this.isPropertyWritable(key)) {
          return;
        }

        if (typeof input[key] !== 'undefined') {
          if (key === 'Tags') {
            input[key].forEach(tag => {
              this[key].push(new Tag().deserialize(tag));
            });
          } else {
            this[key] = input[key];
          }
        }
      });
    return this;
  }
}

export class AddedBy extends BaseModel {
  Name: string = null;
  Type: number = null;
  Date: dayjs.Dayjs = null;
  Photo: string = null;

  deserialize(input: any): this {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (!this.isPropertyWritable(key)) {
          return;
        }

        if (typeof input[key] !== 'undefined') {
          if (key === 'Date') {
            this[key] = dayjs(input[key], DateFormatConstants.YearMonthDayFormat);
          } else {
            this[key] = input[key];
          }
        }
      });
    return this;
  }
}

export class Configuration {
  banners: string[];
}

export class TalentPoolCandidateDetailed extends Candidate {
  TotalAvailabilityDays: number = null;
  CompliancePercentage?: number = null;
  MemberSince: Dayjs = null;
  About: string = null; 
  CVAssetId?: number = null;
  JobList: any[] = [];
  Reviews: any = null;
  VideoURL: string = null;
  Courses: any[] = [];
  TagCategories: MemberTagCategory[] = [];
}

export class CandidateLongTermPreference {
  CandidateId: string;
  AllowLongTermInvitations: boolean;
  LongTermAvailableFrom?: Dayjs;
  Roles?: string[];

  deserialize(input): any {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (key === 'LongTermAvailableFrom') {
          this[key] = dayjs(input[key], DateFormatConstants.YearMonthDayFormat);
        } else if (typeof input[key] !== 'undefined') {
          this[key] = input[key];
        }
      });
    return this;
  }
}
