import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Params, ActivatedRoute } from '@angular/router';
import { AuthService, RegistrationService, ClientService, OpogoService } from '@app/services';
import { ClientDataStoreService } from '@app/services/client-data-store.service';


@Component({
  selector: 'app-embedded-sign-in',
  templateUrl: './embedded-sign-in.component.html',
  styleUrls: ['./embedded-sign-in.component.scss']
})
export class EmbeddedSignInComponent implements OnInit {
  // returnUrl: string;
  isSchool: boolean;
  isJobSeeker: boolean;

  constructor(
    private auth: AuthService,
    private registrationService: RegistrationService,
    private clientService: ClientService,
    private clientStore: ClientDataStoreService,
    private opogoService: OpogoService
  ) {}

  serverError: string;
  loginForm: FormGroup;
  loggedIn: boolean;
  showLoader: boolean;
  permissions: any;
  webViewPermission: boolean;
  @ViewChild('opogousr') opogousrInput: ElementRef;

  ngOnInit(): void {

    this.auth.logout();
    this.loginForm = new FormGroup({
      'opogousr': new FormControl('', {
        validators: [Validators.required, Validators.email],
        updateOn: 'blur'
      }),
      'opogopsw': new FormControl('', [Validators.required])
    }, {
      updateOn: 'change'
    });

  }

  onLogin(): void {
    if (this.loginForm.valid) {
      this.showLoader = true;
      this.auth.login(this.loginForm.get('opogousr').value, this.loginForm.get('opogopsw').value).then((reponse) => {
        this.loggedIn = true;
        this.showLoader = false;
        this.registrationService.getToken(this.loginForm.get('opogousr').value, this.loginForm.get('opogopsw').value).subscribe(
          result => {
            this.permissions = JSON.parse(result.permissions);
            if (this.permissions.some((item) => item === 2)) {
              this.webViewPermission = true;
            }
          },
          error => {
            console.log(error);
          }
        );
        this.redirectUser();
      }).catch((error) => {
        this.showLoader = false;
        console.log('login', error);
        this.serverError = error.error.error_description;
      });
    }
  }

  redirectUser() {
    this.clientStore.clientSummary$.subscribe(
      result => {
        this.isSchool = result.ClientId ? true : false;
        if (this.isSchool) {
          window.top.location.href = this.opogoService.appUrl;
        } else {
          this.registrationService.getLoggedInUserDetails().subscribe(
            result => {
              // console.log('is job seeker?', result.IsJobSeeker);
              this.isJobSeeker = result.IsJobSeeker;
              if (this.isJobSeeker) {
                window.top.location.href = this.opogoService.websiteUrl; // we don't have a /jobs endpoint these days, so it is removed
              } else {
                window.top.location.href = this.opogoService.websiteUrl;
              }
            },
            error => {
              console.log('Could not get LoggedInUserDetails', error);
            }
          );
        }
      },
      error => {
        console.log('client summary error:', error);
      }
    );
  }
}
