import dayjs from 'dayjs';

export interface Candidate {
  JourneyTime: any;
  LastLocationTime: any;
  ClientPastJobs: number;
  ClientBlacklistedCandidate: boolean;
  CandidateBlacklistedClient: boolean;
  MatchScore: number;
  Likes: number;
  JobInviteStatus: JobOfferStatus;
  Email: string;
  CandidateId: string;
  UserId: string;
  Image: string;
  FirstName: string;
  LastName: string;
  OtherNames: string;
  FullName: string;
  PhoneNumber: string;
  CV: string;
  Location: Location;
  Compliance: number;
  PrimaryProfile: CandidateCard;
  SecondaryProfiles: CandidateCard[];
  Tags: MemberTag[];
  Rate: number;
  TimeToJobEstimate: number;
  CandidateAvailability: CandidateAvailability[];
  PersonalStatement: string;
  ComplianceDocuments: ComplianceDocument[];
  PartnerId: string;
  SelectedMemberType: string;
  PaymentCompanyId: string;
  IsAvailable: boolean;
  InTalentPool?: boolean;
  Recommended: boolean;
  RecommendedBy?: string;
  RecommendedByPhotoURL?: string;
  TopMatch: boolean;
  ReadyToGo: boolean;
  ReviewScore?: number;
  BookAgain?: number;
  CourseCount: number;
}

export interface MemberTag {
  Id: string;
  TagCategoryId: string;
  TagCategoryName: string;
  Name: string;
  CategoryType: number;
}

export enum RoleType {
  all = 0,
  recent = 1,
  pinned = 2
}

export interface AvailabilityDay {
  Date: Date | string | dayjs.Dayjs;
  AvailabilityType: number;
}

export interface CandidateParameters {
  FetchParameters: CandidateFetchParameters;
  FilterParameters: CandidateFilterParameters;
}

export interface CandidateFetchParameters {
  Type: TalentPoolFilterType;
  ReturnAvailability: true;
  ReturnJourneyTime: true;
  OrderBy: number;
  PageNr: number;
  PageSize: number;
}

export interface CandidateFilterParameters {
  Search: string;
  JobType: number;
  AvailabilityFrom: string;
  AvailabilityTo: string;
  ReturnedMembers?: MemberAvailabilityCondition;
  MinJobs: number;
  MaxJobs: number;
  FavouriteOnly: boolean;
  MemberAvailabilityCondition?: number;
  // Empty list means return all groups,
  // otherwise returns only for the specified group
  RoleGroup?: number;
  SelectedTags: string[];
}

export interface Location {
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  PostCode: string;
  Latitude: number;
  Longitude: number;
  Address: string;
  AddressDescription: string;
}

export interface CandidateCard {
  Id: number;
  CandidateId: string;
  RoleId: string;
  Type: number;
  CandidateCardTags: MemberTag[];
  RoleName: string;
  Rate: number;
  DailySupplyRate: number;
  LongTermRate: number;
  HourlyRate: number;
  DailySupplyHalfDayRate: number;
  DailySupplyOvertimeHourlyRate: number;
}

export interface CandidateAvailability {
  Id: number;
  CandidateId: string;
  Date: Date;
  AvailabilityType: number;
}

export interface ComplianceDocument {
  Id: string;
  CandidateId: string;
  Status: ComplianceStatus;
  Notes: string;
  ComplianceTypeName: string;
  ComplianceDocumentAttacments: ComplianceDocumentAttacment[];
}

export interface ComplianceDocumentAttacment {
  Id: string;
  ComplianceId: string;
  FileType: string;
  DocumentUrl: string;
  CreatedBy: string;
  FileName: string;
}

export enum ComplianceStatus {
  NotRequested = 1,
  Requested = 2,
  UnableToSupply = 3,
  Received = 4,
  Approved = 5,
  Expired = 6,
  NotApplicable = 7,
  Rejected = 8
}

export enum JobOfferStatus {
  EclipseFilled = -1,
  New = 0,
  Open = 1,
  Sent = 2,
  Rejected = 3,
  Accepted = 4,
  Applied = 5,
  Closed = 6,
  Expired = 7,
  ApplicationRejected = 8,
  InterviewPlaced = 9,
  InterviewRejected = 10,
  InterviewAccepted = 11,
  JobOffered = 12,
  JobOfferedRejected = 13,
  JobOfferedAccepted = 14,
  JobOfferedExpired = 15,
  Placed = 16
}

export enum MemberAvailabilityCondition {
  Everyone = 0,
  PartialAvailable = 1,
  FullAvailable = 2
}

export enum MemberType {
  Everyone = 0,
  TalentClientAdded = 1,
  TalentRecommended = 2,
  NonTalentMembers = 3
}

export enum MatchSort {
  MatchScore = 0,
  RecentBooked = 1,
  MostBooked = 2,
  BankStaff = 3,
  Favourites = 4
}

export enum TalentPoolFilterType {
  Pool = 1,
  Recommended = 2,
  Approved = 3,
  PoolAndRecommended = 4
}

export enum ClientTalentPoolFilterOrderBy {
  MatchedAsc = 1,
  MatchedDesc = 2,
  DistanceAsc = 3,
  DistanceDesc = 4,
  RecentBookedAsc = 5,
  RecentBookedDesc = 6,
  FrequentBookedAsc = 7,
  FrequentBookedDesc = 8,
  NameAsc = 9,
  NameDesc = 10,
  RecentAddedAsc = 11,
  RecentAddedDesc = 12,
  AvailableDaysAsc = 13,
  AvailableDaysDesc = 14
}
