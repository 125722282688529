import { Dayjs } from 'dayjs';
import { Location, VacancyDay, Candidate, ServiceFee } from '.';

export interface Client {
  Id: string;
  Name: string;
  Location: Location;
  EclipseUId: string;
  PortalId: string;
  ContactName: string;
  LastModified: Date;
  ReferenceNumber: string;
  ClientContacts: ClientContact[];
  ClientSites: ClientSite[];
  ConsultantId: string;
  LogoImageUrl: string;
  LogoIconImageUrl: string;
  RateCardId: number;
  RateCard: RateCard;
  Timezone: string;
  Website: string;
  PhoneNumber: string;
  Email: string;
  ParentClientId: string;
  InvoiceOrganization: number;
  ClientTypeId: number;
  ClientSiteType: ClientSiteType;
  Score: number;
  Notes: string;
  ConsultantFirstName: string;
  ConsultantLastName: string;
  DefaultClientSite: ClientSite;
  DefaultBookingStartTimes: BookingStartTimes;
  CustomRateCards: CustomRateCard[];
  Clients: Client[];
  TalentConsultant: any;
  AccountManager: any;
}

export interface ClientContact {
  Id: string;
  AspNetUserId: string;
  ClientId: string;
  JobTitle: string;
  PrimaryContact: boolean;
  ClientSiteId: string;
  FirstName: string;
  LastName: string;
  ContactType: number;
  PhoneNumber: string;
  ContactEmail: string;
  OtherNames: string;
  JobConfirmations: boolean;
  FullName: string;
  MemberId: string;
}

export interface ClientSite {
  Id: string;
  ClientId: string;
  ClientContactId: string;
  Name: string;
  PostCode: string;
  Borough: string;
  Latitude: number;
  Longitude: number;
  AddressLine1: string;
  AddressLine2: string;
  AddressLine3: string;
  AddressLine4: string;
  IsPrimary: boolean;
  ClientContacts: ClientContact[];
}

export interface ClientSiteType {
  Id: number;
  Name: string;
  Description: string;
}

export interface ClientSummary {
  ClientId: string;
  ClientName: string;
  ClientSiteId: string;
  ClientSiteName: string;
  ClientContactId: string;
  MemberUserId: string;
  FirstName: string;
  LastName: string;
  OtherNames: string;
  Email: string;
  PhoneNumber: string;
  Position: string;
  ProfileImageUrl: string;
  Permissions: number[];
  IsPartnerMember: boolean;
  PartnerLogoUrl: string;
  Timezone: string;
  ClientDetails: Client;
  ServiceFee: ServiceFee;
  BankStaffServiceFee: ServiceFee;
  AccountManager: any;
  TalentConsultant: any;
}

export interface RateCard {
  Id: number;
  Name: string;
}

export interface CustomRateCard {
  Id: number;
  Name: string;
  DayRate: number;
  HalfDayRate: number;
  JobRoleId: string;
  JobRoleName: string;
  ClientId: string;
  VacancyDays: VacancyDay[];
}

export interface Reference {
  Id: number;
  CandidateId: string;
  FirstName: string;
  LastName: string;
  Email: string;
  Phone: string;
  DateFrom: Date;
  DateTo: Date;
  Subjects: string;
  AgeGroup: string;
  ComplianceTypeId: number;
  CreatedOn: Date;
  CreatedBy: string;
  Candidate: Candidate;
  ComplianceType: any;
  ScorePupilRelationship: ReferenceScore;
  ScoreColleagueRelationship: ReferenceScore;
  ScoreAttendance: ReferenceScore;
  ScoreKnowledge: ReferenceScore;
  ScorePlanning: ReferenceScore;
  ScoreComms: ReferenceScore;
  ReEmploy: boolean;
  Safeguard: boolean;
  SafeguardNotes: string;
  ReceivedOn: Date;
  OrganisationName: string;
  JobTitle: string;
}

export interface BookingStartTimes {
  Morning: Dayjs;
  Afternoon: Dayjs;
  StartTimeClubBefore: Dayjs;
  StartTimeClubMid: Dayjs;
  StartTimeClubAfter: Dayjs;
}

export enum ReferenceScore {
  Poor = 1,
  BelowAvrg = 2,
  Satisfactory = 3,
  Good = 4,
  Excellent = 5
}
