import { Pipe, PipeTransform } from '@angular/core';
import dayjs from 'dayjs';
import { IDatePickerConfig } from 'ng2-date-picker';


export enum RangeTypes {
  start = 0,
  end = 1
}

export enum DayTypes {
  full = 0,
  am = 1,
  pm = 2,
  pdAm = 6,
  pdPm = 7
}

@Pipe({
  name: 'timeRangeConfig'
})
export class TimeRangeConfigPipe implements PipeTransform {

  transform(type: DayTypes): any {
    const config: IDatePickerConfig = <any>{
      minutesInterval: 15,
    };

    if (type === DayTypes.full) {
      config.maxTime = dayjs('23:59', 'hh:mm');
      config.minTime = dayjs('00:01', 'hh:mm');
    } else if (type === DayTypes.am || type === DayTypes.pdAm) {
      config.maxTime = dayjs('12:00', 'hh:mm');
      config.minTime = dayjs('00:01', 'hh:mm');
    } else if (type === DayTypes.pm || type === DayTypes.pdPm) {
      config.maxTime = dayjs('23:59', 'hh:mm');
      config.minTime = dayjs('12:00', 'hh:mm');
    }

    return config;
  }

}
