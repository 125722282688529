import { ChangeDetectorRef, Component, OnDestroy} from '@angular/core';
import { Router, NavigationEnd, ActivatedRoute } from '@angular/router';
import { MenuService } from './services/menu.service';
import { DeviceService } from '@services/device.service';
import { filter, last, map } from 'rxjs/operators';
import { AuthService, HeaderService } from '@services';
import { VersionCheckService } from './services/version-check.service';
import { animate, style, transition, trigger } from '@angular/animations';
import { ClientDataStoreService } from './services/client-data-store.service';
import { ClientTree } from './components/client-select-dropdown/client-select-dropdown.component';
import { JobSetupService } from './services/job-setup.service';
import { Subscription } from 'rxjs';

// noinspection TypeScriptUnresolvedFunction
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [
    trigger('fadeInOut', [
      transition(':enter', [
        style({ opacity: 0 }),
        animate('300ms ease-in', style({ opacity: 1 })),
      ]),
      transition(':leave', [
        animate('300ms ease-out', style({ opacity: 0 })),
      ]),
    ]),
  ],
})
export class AppComponent implements OnDestroy {
  isCourses: boolean;
  hideShell = false;
  oldVersion = {version: '1.2.10'};
  newVersion;
  config;
  loggedIn = false;

  get clientLoaded$() {
    return this.clientStore?.clientLoaded$;
  }

  subscriptions: Subscription[] = [];
  jobRoleConfigSubscription: Subscription;

  constructor(
      private auth: AuthService,
      private route: ActivatedRoute,
      public clientStore: ClientDataStoreService,
      private jobSetupService: JobSetupService,
      public router: Router,
      public menuService: MenuService,
      public headerService: HeaderService,
      public device: DeviceService,
      private versionCheckService: VersionCheckService
  ) {
    this.loggedIn = this.auth.isLoggedIn();
    setInterval(() => {
      this.versionCheckService.getUpdate()
          .subscribe((data) => {
            this.newVersion = data;
            this.newVersion = this.newVersion.version;

            if (this.oldVersion.version !== this.newVersion && this.newVersion !== localStorage.getItem('version')) {
              this.oldVersion.version = this.newVersion;
              localStorage.setItem('version', this.newVersion);
              
              if (!this.hideShell) {
                this.auth.ThirdPartyServicesAuthenticated = false;
              }
              
              location.reload();
            }
          });
    }, 15000);

    this.subscriptions.push(this.router.events
      .pipe(filter(event => event instanceof NavigationEnd))
      .subscribe((event: any) => {
        let child = this.route;
        while (child.firstChild) {
          child = child.firstChild;
        }
        this.isCourses = !!child.snapshot.data.isCourses;
        this.hideShell = !!child.snapshot.data.hideShell;

        if (this.auth.isLoggedIn() && !this.hideShell && !this.auth.ThirdPartyServicesAuthenticated) {
          this.AuthenticateThirdPartyServices();
        }
    }));

    if (this.loggedIn) {
      this.initClientApp();
    }

    this.subscriptions.push(this.auth.LogInEvent.asObservable().subscribe(_ => {
      this.loggedIn = this.auth.isLoggedIn();
      this.initClientApp();
      this.clientStore.refreshClientLoad();
    }));

    this.subscriptions.push(this.auth.LogOutEvent.asObservable().subscribe(_ => {
      this.loggedIn = this.auth.isLoggedIn();
      this.clientStore.clearAll();
      this.clientStore.destroy();
      localStorage.removeItem('selectedClient');
    }));
  }

  private initClientApp() {
    this.clientStore.getClientTree()
      .subscribe(clientTree => {
        let clientId: string | null = null;

        if (clientTree.Clients.length > 0) {
          let lastSelectedClientId = localStorage.getItem('selectedClient');
          
          if (!lastSelectedClientId)
            clientId = this.getFirstClientWithoutChildren(clientTree).Id;
          else 
            clientId = lastSelectedClientId;
        }

        this.subscriptions.push(this.clientStore.clientSummary$
          .subscribe(clientSummary => {
            this.jobRoleConfigSubscription?.unsubscribe();
            this.clientStore._roleConfig.next(null);
            this.jobRoleConfigSubscription = this.jobSetupService.refreshJobRoleSetupData(clientSummary.ClientId).subscribe(roleConfig => {
              this.clientStore._roleConfig.next(roleConfig);
            });
          }));

        this.clientStore.selectClient(clientId).subscribe(_ => {}, error => this.handleError(error))
      },
    error => this.handleError(error));
  }

  AuthenticateThirdPartyServices() {
    this.clientStore.clientSummary$.subscribe(data => {
      if (!data) {
        return;
      }

      if (data.MemberUserId) {
        try {
          (window as any).smartlook('identify', data.MemberUserId, {
            "schoolName": data.ClientName
          })
        }
        catch(e) {}
      }
  
      this.auth.ThirdPartyServicesAuthenticated = true;
    });
  }

  getFirstClientWithoutChildren(client: ClientTree): ClientTree {
    if (client.Clients.length === 0) {
      return client;
    }

    return this.getFirstClientWithoutChildren(client.Clients[0])
  }

  private handleError(error: Response | any) {
    console.log(error);
    this.auth.logout();
  }

  ngOnDestroy(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    localStorage.removeItem('selectedClient');
  }
}
