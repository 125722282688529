/* Modules */
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';

import firebase from 'firebase/compat/app';

import { MatRadioModule } from '@angular/material/radio' 
import { MatDialogModule } from '@angular/material/dialog' 
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalModule } from 'ngx-bootstrap/modal';
import { DeviceDetectorService } from 'ngx-device-detector';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';


/* Components */
import { AppComponent } from './app.component';
import { ErrorPageComponent } from '@app/components/error-page/error-page.component';
import { LoginComponent } from '@components/auth-pages/login/login.component';
import { DashboardComponent } from '@components/dashboard/dashboard.component';
import { HeaderComponent } from '@components/header/header.component';
import { FooterComponent } from '@components/footer/footer.component';
import { ForgotPasswordComponent } from '@components/auth-pages/forgot-password/forgot-password.component';
import { ChangePasswordComponent } from '@components/auth-pages/change-password/change-password.component';
import { ForgotPasswordEmailConfirmationComponent } from '@components/auth-pages/forgot-password-email-confirmation/forgot-password-email-confirmation.component';
import { PasswordResetSuccessfulComponent } from '@components/auth-pages/password-reset-successful/password-reset-successful.component';
import { ReferenceComponent } from '@components/reference/reference.component';
import { TutorialsComponent } from '@components/tutorials/tutorials.component';
import { AccountNavListComponent } from '@components/nav-list/account-nav-list/account-nav-list.component';
import { HubNavListComponent } from '@components/nav-list/hub-nav-list/hub-nav-list.component';
import { OutsideImgContainerComponent } from '@components/outside-img-container/outside-img-container.component';
import { NotificationsComponent } from './components/notifications/notifications.component';

// embedded sign-up section
import { SignUpComponent } from '@components/embedded-forms/sign-up/sign-up.component';
import { ValidateEmailComponent } from '@components/embedded-forms/validate-email/validate-email.component';
import { EmbeddedSignInComponent } from '@components/embedded-forms/embedded-sign-in/embedded-sign-in.component';
import { EmbeddedSigninPanelComponent } from '@components/embedded-forms/embedded-signin-panel/embedded-signin-panel.component';
import { SetPasswordComponent } from '@components/embedded-forms/set-password/set-password.component';
import { EmbeddedForgottenPasswordComponent } from '@components/embedded-forms/embedded-forgotten-password/embedded-forgotten-password.component';
import { SignedInComponent } from '@components/embedded-forms/signed-in/signed-in.component';
import { SignoutComponent } from '@components/embedded-forms/signout/signout.component';
import { MobileMenuComponent } from '@components/mobile-menu/mobile-menu.component';

import { AppSharedModule } from './app-shared.module';
import { SharedModule } from './shared.module';
import { ChatModule } from './modules/chat/chat.module';

// image upload and crop package
import {
  LyThemeModule,
  LY_THEME
} from '@alyle/ui';
import { LyIconModule } from '@alyle/ui/icon';
import { LyButtonModule } from '@alyle/ui/button';
import { LyToolbarModule } from '@alyle/ui/toolbar';
import { LyImageCropperModule } from '@alyle/ui/image-cropper';
import { MinimaLight, MinimaDark } from '@alyle/ui/themes/minima';

import './extensions/string.extensions';
import './extensions/date.extensions';
import { RouteReuseStrategy, UrlSerializer } from '@angular/router';
import { LowerCaseUrlSerializer } from './helpers/lowercase-url.serializer';
import { ClientDataStoreService, JobService, SubjectService, TalentPoolService } from '@app/services';
import { DeviceService } from '@services/device.service';

import {
  HammerGestureConfig,
  HAMMER_GESTURE_CONFIG
} from '@angular/platform-browser';

import * as Hammer from 'hammerjs';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { ClickOutsideModule } from 'ng-click-outside';
import { AutofillValueAccessor } from '@directives/autofill-value-accessor.directive';

import { AuthorizationPipe } from '@pipes/authorization.pipe';
import { SorryPageComponent } from './components/auth-pages/sorry-page/sorry-page.component';

import { APP_INITIALIZER } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';
import { ConfigService } from './modules/addjobs/components/banner-block/config.service';
import { VersionCheckService } from './services/version-check.service';
import { DateAgoPipe } from './pipes/date-ago.pipe';
import { getAnalytics } from 'firebase/analytics';
import { ReferenceQuestionnaireComponent } from './components/reference/questionnaires/reference-questionnaire.component';
import { LogcQuestionnaireComponent } from './components/reference/questionnaires/logc-questionnaire.component';
import { CharacterReferenceQuestionnaireComponent } from './components/reference/questionnaires/character-reference-questionnaire.component';
import { DpDatePickerModule } from 'ng2-date-picker';
import { CustomReuseStrategy } from './routing-strategy';
import { CommonModule } from '@angular/common';
import { DateService } from './services/date.service';
import { AvailabilityDayPipe } from './pipes/availability-day.pipe';
import { ControlsModule } from './controls/controls.module';
import { LocalizationModule } from './localization/localization.module';
import { FeatureFlagDirective } from './directives/feature-flag.directive';
import { LocalizedFeatureFlagsService } from './localization/services/localized-feature-flags.service';
import { FEATURE_FLAGS_SERVICE } from './interfaces';
import { AuthLoginGuardService } from './guards';
import { JobSetupService } from './services/job-setup.service';
import { SupplyService } from './services/supply.service';
import { ClientSelectDropdownComponent } from './components/client-select-dropdown/client-select-dropdown.component';
import { AppLoadingScreenComponent } from './components/app-loading-screen/app-loading-screen.component';

export class HammerConfig extends HammerGestureConfig {
  overrides = {
    pan: {
      direction: 6
    },
    pinch: {
      enable: false
    },
    rotate: {
      enable: false
    }
  };
}

export function ConfigLoader(configService: ConfigService) {
  return () => configService.load(environment.bannerFile);
}

export function CreateFeatureFlagService() {
  return new LocalizedFeatureFlagsService(environment.FEATURE_FLAGS);
}

const app = firebase.initializeApp(environment.firebaseConfig);
const analytics = getAnalytics(app);

declare global{
  interface Navigator{
     msSaveBlob:(blob: Blob,fileName:string) => boolean
     msSaveOrOpenBlob:(blob: Blob,fileName:string) => boolean 
    }
  }

@NgModule({
  declarations: [
    AppComponent,
    ErrorPageComponent,
    LoginComponent,
    OutsideImgContainerComponent,
    DashboardComponent,
    HeaderComponent,
    FooterComponent,
    ForgotPasswordComponent,
    ChangePasswordComponent,
    ForgotPasswordEmailConfirmationComponent,
    PasswordResetSuccessfulComponent,
    MobileMenuComponent,
    ReferenceComponent,
    SignUpComponent,
    ValidateEmailComponent,
    EmbeddedSignInComponent,
    SetPasswordComponent,
    EmbeddedForgottenPasswordComponent,
    SignedInComponent,
    SignoutComponent,
    EmbeddedSigninPanelComponent,
    TutorialsComponent,
    HubNavListComponent,
    AccountNavListComponent,
    AutofillValueAccessor,
    AuthorizationPipe,
    SorryPageComponent,
    NotificationsComponent,
    DateAgoPipe,
    ReferenceQuestionnaireComponent,
    LogcQuestionnaireComponent,
    CharacterReferenceQuestionnaireComponent,
    AvailabilityDayPipe
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    MatRadioModule,
    MatDialogModule,
    AppSharedModule,
    SharedModule,
    AppRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    ModalModule.forRoot(),
    LyThemeModule.setTheme('minima-dark'), // or minima-light
    LyButtonModule,
    LyToolbarModule,
    LyIconModule,
    LyImageCropperModule,
    BsDatepickerModule.forRoot(),
    DpDatePickerModule,
    ServiceWorkerModule.register('sw-master.js', {enabled: environment.production}),
    ClickOutsideModule,
    HttpClientModule,
    ChatModule,
    CommonModule,
    ControlsModule,
    LocalizationModule,
    FeatureFlagDirective,
    ClientSelectDropdownComponent,
    AppLoadingScreenComponent
  ],
  providers: [
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer
    },
    {provide: LY_THEME, useClass: MinimaLight, multi: true},
    {provide: LY_THEME, useClass: MinimaDark, multi: true},
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: RouteReuseStrategy, useClass: CustomReuseStrategy
    },
    TalentPoolService,
    JobService,
    JobSetupService,
    DeviceDetectorService,
    DateService,
    DeviceService,
    SubjectService,
    VersionCheckService,
    ConfigService,
    LocalizedFeatureFlagsService,
    AuthLoginGuardService,
    ClientDataStoreService,
    {
      provide: FEATURE_FLAGS_SERVICE,
      useFactory: CreateFeatureFlagService
    },
    {
      provide   : APP_INITIALIZER,
      useFactory: ConfigLoader,
      deps      : [ConfigService],
      multi     : true
    },
  ],
  bootstrap: [AppComponent]
})
export class AppModule {
}
