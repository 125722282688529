import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';

import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';

/* Services */
import { CookieService } from 'ngx-cookie-service';
import { AuthGuard, CanActivateGuard, CanDeactivateGuard } from './guards';
import { AuthInterceptor, CacheInvalidatorInterceptor } from './interceptors';
import { UserInfoResolver, ExpertsResolver, LoginResolver } from './resolvers';
import {
  AuthService,
  CandidateService,
  ChatService,
  ClientService,
  ContentService,
  HeaderService,
  MenuService,
  SignalRService,
  RegistrationService,
  CachingService,
  LearnService,
  OpogoService,
  MobileService
} from './services';
import { ConfirmDialogComponent, InfoDialogComponent, SimpleConfirmDialogComponent } from './components/modals';
import { NotificationsService } from './services/notifications.service';
import { PushNotificationsService } from './services/push-notifications.service';
import { TalentRemovalDialogComponent } from './components/modals/talent-removal-dialog/talent-removal-dialog.component';
import { SupplyService } from './services/supply.service';
import { ClientTermsResolver } from './resolvers/client-terms.resolver';
import { RoleConfigResolver } from './resolvers';


@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    HttpClientModule
  ],
  declarations: [
    ConfirmDialogComponent,
    InfoDialogComponent,
    SimpleConfirmDialogComponent,
    TalentRemovalDialogComponent
  ],
  providers: [
    AuthService,
    AuthGuard,
    HeaderService,
    UserInfoResolver,
    ClientTermsResolver,
    RoleConfigResolver,
    LoginResolver,
    SignalRService,
    CanActivateGuard,
    CanDeactivateGuard,
    CandidateService,
    ContentService,
    MenuService,
    ClientService,
    ChatService,
    LearnService,
    SupplyService,
    OpogoService,
    MobileService,
    RegistrationService,
    CookieService,
    {
      provide: HTTP_INTERCEPTORS, 
      useClass: CacheInvalidatorInterceptor,
      multi: true
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    ExpertsResolver,
    NotificationsService,
    PushNotificationsService,
    CachingService
  ]
})
export class AppSharedModule {
}
export { NotificationType, NotificationIcon, NotificationConfig } from './services/notifications.service';
