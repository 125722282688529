import { Injectable } from '@angular/core';
import dayjs from 'dayjs';
import { Dayjs } from 'dayjs';

export interface CacheDetails {
  Id: number,
  Name: string,
  Cache: any,
  CacheTime: Dayjs
}

@Injectable({
  providedIn: 'root'
})
export class CachingService {

  cachedData: CacheDetails[] = [
    {
      Id: 0,
      Name: 'UserInfo',
      Cache: null,
      CacheTime: null
    }
  ] 

  constructor() { }

  cacheData(id: number, cache: any) {
    let cachedData = this.cachedData.find(c => c.Id === id);

    if (cachedData) {
      cachedData.Cache = cache;
      cachedData.CacheTime = dayjs();

      let self = this;
      setTimeout(function () {
        self.clearCacheItem(id);
      }, 1000 * 60 * 60) // one hour
    }
  }

  getCacheItem(id: number): any {
    return this.cachedData.find(c => c.Id === id);
  }

  clearCacheItem(id: number) {
    let cachedData = this.cachedData.find(c => c.Id === id);

    cachedData.Cache = null;
    cachedData.CacheTime = null;
  }

}
