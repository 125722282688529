import { throwError as observableThrowError, Observable, of } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { AuthService } from './auth.service';

export interface HttpResponseBase<T> {
  IsSuccess: boolean;
  Message: string;
  Content?: T;
}

@Injectable()
export class HttpService {
  private BASE_URL: string = environment.apiBaseURL;

  private OPOGOPLATFORM_BASE_URL: string = environment.opogoPlatformApiUrl;

  private OPOGOPLATFORM_URLS: string[] = [
    '/client/getclientbyloggeduser'
  ]

  constructor(private auth: AuthService, private http: HttpClient) {
  }

  get(url: string, options?: any): Observable<any> {
    url = `${this.getBaseUrl(url)}${url}`;
    return this.http.get(url, options);
  }

  getNoLoggedIn(url: string, options?: any): Observable<any> {
    url = `${this.getBaseUrl(url)}${url}`;
    return this.http.get(url, options);
  }

  post(url: string, body: any, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.getBaseUrl(url)}${url}`;
      return this.http.post(url, body, options);
    }
    return of(null);
  }

  postNoLoggedIn(url: string, body: any, options?: any): Observable<any> {
    url = `${this.getBaseUrl(url)}${url}`;
    return this.http.post(url, body, options);
  }

  postToken(body: any, options?: any): Observable<any> {
    let baseUrl = `${this.BASE_URL}`.endsWith('/api') ? `${this.BASE_URL}`.slice(0, -4) : `${this.BASE_URL}`;
    let url: string = `${baseUrl}/token`;
    return this.http.post(url, body, options);
  }

  put(url: string, body: any, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.getBaseUrl(url)}${url}`;
      return this.http.put(url, body, options);
    }
    return of(null);
  }

  delete(url: string, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.getBaseUrl(url)}${url}`;
      return this.http.delete(url, options);
    }
    return of(null);
  }

  request(method: string, url: string, options?: any): Observable<any> {
    if (this.auth.isLoggedIn()) {
      url = `${this.getBaseUrl(url)}${url}`;
      return this.http.request(method, url, options);
    }
    return of(null);
  }

  private getBaseUrl(url: string) {
    if (this.OPOGOPLATFORM_URLS.includes(url.toLowerCase())) {
      return this.OPOGOPLATFORM_BASE_URL;
    }

    return this.BASE_URL;
  }
}
