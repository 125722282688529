import { JobRole } from "@app/models";

export class JobRoleTree extends Array<JobRoleTreeItem> {
    readonly length: number;
  
    readonly [n: number]: JobRoleTreeItem;
  
    private constructor(items?: Array<JobRoleTreeItem>) {
      super(...items);
    }
  
    static create<T>(): JobRoleTree {
      return Object.create(JobRoleTree.prototype);
    }
  
    setItems(data: any) {
      data.forEach(role => {
        this.push(new JobRoleTreeItem().deserialize(role));
      });
    }
  
    findById(id: string) {
      if (!id) {
        return null;
      }
      return this.findChild(id);
    }
  
    findChild(id: string, roles: JobRoleTree = this): JobRoleTreeItem {
      let res: JobRoleTreeItem = null;
      for (let i = 0; i < roles.length; i++) {
        if (roles[i].Id === id) {
          res = roles[i];
          break;
        } else if (roles[i].JobRoles.length && !res) {
          res = this.findChild(id, roles[i].JobRoles);
        }
      }
      return res;
    }
  
    getChild(id: string): JobRole {
      if (!id) {
        return null;
      }
      const child = this.find(item => item.Id === id);
      return child ? child : null;
    }
}

type parentGetter = () => JobRoleTreeItem;

export class JobRoleTreeItem extends JobRole {
    JobRoles: JobRoleTree = JobRoleTree.create(); 
    SelectedRole: JobRole = null;
    Expanded: boolean = false;

    private _parentGetter: parentGetter; 

    get Parent(): JobRoleTreeItem {
        return this._parentGetter ? this._parentGetter() : null;
    }

    get HasParent() {
        return !!this.Parent;
    }
    
    get HasChildren() {
        return !!this.JobRoles.length;
    }

    get Root() {
        let jobRole = this.Parent ? this.Parent : this;
        while (true) {
            if (jobRole.HasParent) {
                jobRole = jobRole.Parent;
            } else {
                break;
            }
        }

        return jobRole;
    }

    constructor() {
        super();
    }

    addJobRoles(input: any, parentGetter?: parentGetter) {
        if (typeof input === 'undefined' || input === null) {
            return this;
        }
    
        input.forEach(role => {
            this.JobRoles.push(new JobRoleTreeItem().deserialize(role, () => {
                return this;
            }));
        });
    
        if (parentGetter) {
            this._parentGetter = parentGetter;
        }

        return this;
    }

    setSelected(input: JobRoleTreeItem) {
        this.SelectedRole = input;
        this.Expanded = true;
        const parent = this.Parent;
        if (parent) {
            parent.setSelected(this);
        }
    }

    clearSelected(fromRoot: boolean = false) {
        this.Expanded = false;
        if (!fromRoot) {
            let parent: JobRoleTreeItem = this.Parent;
            while (parent) {
                parent.Expanded = false;
                parent.SelectedRole = null;
                parent = parent.Parent;
            }
        } else {
            const root = this.Root;
            let selected = root.SelectedRole;
            root.SelectedRole = null;
            root.expanded = false;
            while (selected) {
                const temp = selected.SelectedRole;
                selected.SelectedRole = null;
                selected.expanded = false;
                selected = temp;
            }
        }
    }

    deserialize(input: any, parentGetter?: parentGetter) {
        if (typeof input === 'undefined' || input === null) {
            return this;
        }
    
        Object.keys(this).forEach(key => {
            if (key === 'JobRoles') {
                input[key].forEach(role => {
                    this.JobRoles.push(new JobRoleTreeItem().deserialize(role, () => this))
                });
            } else {
                this[key] = input[key];
            }
        });

        if (parentGetter) {
            this._parentGetter = parentGetter;
        }
        
        return this;
    }
}