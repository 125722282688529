import { CommonModule } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { ClientDataStoreService } from '@app/services/client-data-store.service';
import { filter, Subscription } from 'rxjs';

export class ClientTree {
  Id: string;
  Name: string;
  LogoIconImageUrl: string;
  Clients: ClientTree[];
  Expanded: boolean;

  constructor() {
    this.Expanded = false;
  }

  public deserialize(input: any) {
    if (typeof input === 'undefined' || input === null) {
      return this;
    }

    Object.keys(input)
      .forEach(key => {
        if (typeof input[key] !== 'undefined') {
          this[key] = input[key];
        }
    });
    
    return this;
  }
}

@Component({
  selector: 'app-client-select-dropdown',
  standalone: true,
  imports: [
    CommonModule
  ],
  providers: [],
  templateUrl: './client-select-dropdown.component.html',
  styleUrl: './client-select-dropdown.component.scss'
})
export class ClientSelectDropdownComponent {
  mainClient: ClientTree;
  selectedClient: ClientTree;

  clientDropdownExpanded: boolean = false;

  selectClientSubscription: Subscription;

  constructor(private clientStore: ClientDataStoreService) {
    clientStore.clientTree$
      .pipe(filter(client => !!client))
      .subscribe(client => {
        client.Expanded = true;

        this.mainClient = client;
        this.selectedClient = client;

        clientStore.clientSummary$.subscribe(clientSummary => {
          if (clientSummary && clientSummary.ClientId !== this.selectedClient.Id) {
            this.selectedClient = this.getClientFromTreeById(this.mainClient, clientSummary.ClientId);
          }
        });
    });
  }

  toggleDropdown() {
    this.clientDropdownExpanded = !this.clientDropdownExpanded;
  }

  onClientClicked(client: ClientTree) {
    if (client.Id === this.selectedClient.Id || client.Id === this.mainClient.Id) {
      this.toggleDropdown();
      
      return;
    }

    if (client.Clients.length > 0) {
      this.toggleClientChildren(client);
      return;
    }

    this.selectClient(client);
  }

  selectClient(client: ClientTree) {
    this.toggleDropdown();

    this.selectedClient = client;
    localStorage.setItem('selectedClient', client.Id);
    
    this.selectClientSubscription?.unsubscribe();
    this.selectClientSubscription = this.clientStore.selectClient(client.Id).subscribe();
  }

  toggleClientChildren(client: ClientTree) {
    client.Expanded = !client.Expanded; 
  }

  getClientFromTreeById(clientTree: ClientTree, clientId): ClientTree {
    if (clientTree.Id === clientId) {
      return clientTree;
    }

    for (let i = 0; i < clientTree.Clients.length; i++) {
      let client = this.getClientFromTreeById(clientTree.Clients[i], clientId);

      if (client) {
        return client;
      }
    }
  }
}
